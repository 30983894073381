import React from "react"
import TermsUK from "../../../components/body/pages/terms/TermsUk"
import NoheaderFooter from "../../../components/Noheaderfooter"
import SEO from "../../../components/seo"

const TermsUkPage = () => (
  <NoheaderFooter>
    <SEO
      canonical={"https://kuda.com/legal/uk/"}
      title="Terms & Conditions | Kuda | The Money App for Africans"
    />
    <TermsUK />
  </NoheaderFooter>
)

export default TermsUkPage
