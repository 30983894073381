import React, { useEffect, Fragment } from "react"
import { scrollToElement } from "../../../utility/utils"
import PageNotFound from "../404"
const TermsUK = () => {
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])

  return (
    <Fragment>
      <PageNotFound/>
    </Fragment>
  )
}

export default TermsUK
